


















































































import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import { ValidationObserver, setInteractionMode, extend } from "vee-validate";
import { email, min, max, required, ext } from "vee-validate/dist/rules";

import { VacancyApplyModel } from "@/models/requests/Vacancy";
import { VacancyService } from "@/services/Public";
import TextField from "@/components/inputs/TextField/index.vue";
import FilePicker from "@/components/inputs/FilePicker/index.vue";
import Checkbox from "@/components/inputs/Checkbox/index.vue";
import DialogWrapper from "@/views/shared/DialogWrapper.vue";

setInteractionMode("eager");

extend("usPhone", {
  validate(value) {
    return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value);
  },
  message: `Phone is not valid`,
});

extend("min", {
  ...min,
  message: `{_field_} min length is {length}`,
});

extend("max", {
  ...max,
  message: `{_field_} max length is {length}`,
});

extend("email", {
  ...email,
  message: `Email is not valid`,
});

extend("required", {
  ...required,
  message: `This field is required.`,
});

extend("ext", {
  ...ext,
  message: "Only doc, docx, pdf, odt, ttf files allowed",
});

@Component({
  name: "ApplyDialog",
  components: {
    ValidationObserver,
    DialogWrapper,
    TextField,
    FilePicker,
    Checkbox,
  },
})
export default class ApplyDialog extends Vue {
  @Prop({ required: false, default: false })
  public readonly value!: boolean;

  @Prop({ required: true, default: "" })
  public readonly vacancyId!: string;

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  private loading = false;

  private isAgreed = false;

  private vacancyApplyModel: VacancyApplyModel | null = null;

  acceptList: string[] = [
    "application/pdf",
    ".doc",
    ".docx",
    ".xml",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
  ];

  @Watch("value")
  watchValue(value: boolean) {
    if (!value) {
      this.vacancyApplyModel = null;
      this.isAgreed = false;
    } else {
      this.vacancyApplyModel = {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        vacancyId: this.vacancyId,
        file: null,
      };
    }
  }

  async onApply() {
    const formIsValid = await this.$refs.observer.validate();
    if (!this.vacancyApplyModel || !formIsValid) return;

    try {
      this.loading = true;
      await VacancyService.anonymousApply(this.vacancyApplyModel);
      this.$toast.success(
        "Opportunity successfully applied. Сheck spam for confirmation email!"
      );
      this.input(false);
    } catch (e) {
      this.$toast.error("Opportunity applying error!");
      console.error("Vacancy applying error:", e);
    } finally {
      this.loading = false;
    }
  }

  @Emit()
  input(value: boolean) {
    return value;
  }
}
