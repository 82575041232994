








































































import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import dateFormat from "dateformat";
import { VacancyPageModel } from "@/models/Page";
import { EVacancyTypeItems } from "@/models/enums";
import ApplyCardWrapper from "@/views/shared/ApplyCardWrapper.vue";
import EVacancyPageAction from "./models/EVacancyPageAction";
import VacancyPageAction from "./models/VacancyPageAction";

@Component({
  name: "VacancyInfoCard",
  components: {
    ApplyCardWrapper,
  },
})
export default class extends Vue {
  @Prop({ required: true, default: [] })
  public readonly pageActions!: VacancyPageAction[];

  @Prop({ required: true, default: null })
  public readonly vacancy!: VacancyPageModel;

  private EVacancyPageAction = EVacancyPageAction;

  get jobType() {
    return this.vacancy.vacancyTypes
      .map((x) => EVacancyTypeItems.find((y) => y.value === x)?.text)
      .join(", ");
  }

  get datePosted() {
    return dateFormat(this.vacancy.dateOfCreation, "mmm dd, yyyy");
  }

  get avatar() {
    const avatarUrl = this.vacancy.avatar?.resizedUrl;
    return avatarUrl ? avatarUrl : require("@/assets/no-avatar.svg");
  }

  @Emit()
  action(value: EVacancyPageAction) {
    return value;
  }
}
