



































































import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import { IUpdateHeaderFooterArgs } from "@/store/modules/headerFooterInfo";
import { VacancyPageModel } from "@/models/Page";
import { SkillModel } from "@/models/common";
import { ECompensationType, ECandidatePageMenuAction } from "@/models/enums";
import { ChipsList } from "@/components/lists";
import { PageService, VacancyService } from "@/services/Public";
import RemindToApplyDialog from "@/components/dialogs/RemindToApplyDialog.vue";
import PageContainer from "@/views/shared/PageContainer.vue";
import SimilarOpportunities from "@/views/shared/SimilarOpportunities.vue";
import EVacancyPageAction from "./models/EVacancyPageAction";
import VacancyInfoCard from "./VacancyInfoCard.vue";
import VacancyCreatorSection from "./VacancyCreatorSection.vue";
import ApplyDialog from "./dialogs/ApplyDialog.vue";
import VacancyPageAction from "./models/VacancyPageAction";

const HeaderFooterInfo = namespace("headerFooterInfo");

@Component({
  name: "VacancyPage",
  components: {
    PageContainer,
    ChipsList,
    VacancyInfoCard,
    VacancyCreatorSection,
    SimilarOpportunities,
    ApplyDialog,
    RemindToApplyDialog,
  },
})
export default class extends Vue {
  @HeaderFooterInfo.Action("updateData")
  updateHeaderFooterData!: (args?: IUpdateHeaderFooterArgs) => void;

  private vacancy: VacancyPageModel | null = null;

  private loading = false;

  private history = history;

  private showApplyDialog = false;

  private showRemindDialog = false;

  private pageActions: Record<EVacancyPageAction, VacancyPageAction> = {
    [EVacancyPageAction.Apply]: { disabled: false, loading: false },
    [EVacancyPageAction.Remind]: { disabled: false, loading: false },
    [EVacancyPageAction.Save]: { disabled: false, loading: false },
    [EVacancyPageAction.Share]: { disabled: false, loading: false },
  };

  get cover() {
    return this.vacancy?.cover ? this.vacancy.cover.originalUrl : "";
  }

  get skills() {
    const items = this.vacancy?.skills;
    if (!items) return [];
    return items.map((x: SkillModel) => ({ text: x.title }));
  }

  get compensation() {
    let compensation = "";
    if (!this.vacancy) return "";

    if (this.vacancy.compensationType === ECompensationType.Salary) {
      if (this.vacancy.compensationSalary <= 0) return "";
      compensation = `$${this.vacancy.compensationSalary.toLocaleString()}`;
      compensation =
        this.vacancy.compensationSalaryMax > this.vacancy.compensationSalary
          ? `${compensation} - $${this.vacancy.compensationSalaryMax.toLocaleString()}/year`
          : `${compensation}/year`;
    } else {
      if (this.vacancy.compensationHourly <= 0) return "";
      compensation = `$${this.vacancy.compensationHourly.toLocaleString()}`;
      compensation =
        this.vacancy.compensationHourlyMax > this.vacancy.compensationHourly
          ? `${compensation} - $${this.vacancy.compensationHourlyMax.toLocaleString()}/hour`
          : `${compensation}/hour`;
    }

    return compensation;
  }

  async mounted() {
    try {
      this.loading = true;
      const url = this.$router.currentRoute.fullPath;
      this.vacancy = await PageService.getProfile<VacancyPageModel>(url);
      this.mountJobPosting(this.vacancy.id);

      if (this.vacancy.avatar) {
        this.updateHeaderFooterData({
          logoImageUrl: this.vacancy.avatar.fileUrl,
          contactAddress: [
            {
              type: ECandidatePageMenuAction.Contact,
              value: this.vacancy?.companyPhoneNumber,
            },
            {
              type: ECandidatePageMenuAction.Address,
              value: this.vacancy?.companyAddress,
            },
            {
              type: ECandidatePageMenuAction.Button,
              value: this.vacancy?.companyWebsite,
            },
          ],
        });
      }

      this.setAction(EVacancyPageAction.Apply, {
        disabled: this.vacancy.isApplied || this.vacancy.isEmployer,
      });

      this.setAction(EVacancyPageAction.Remind, {
        disabled: this.vacancy.isEmployer,
      });

      this.setAction(EVacancyPageAction.Save, {
        disabled:
          this.vacancy.isFavorite ||
          !this.isAuthenticated() ||
          this.vacancy.isEmployer,
      });

      this.setAction(EVacancyPageAction.Share, {
        disabled: false,
      });
    } catch (e) {
      console.log("Vacancy fetch error: ", e);
    } finally {
      this.loading = false;
    }
  }

  async mountJobPosting(vacancyId: string) {
    const jobPosting = await VacancyService.jobPosting(vacancyId);
    const jobPostingScript = document.createElement("script");
    jobPostingScript.setAttribute("type", "application/ld+json");
    jobPostingScript.textContent = JSON.stringify(jobPosting);
    document.head.appendChild(jobPostingScript);
  }

  setAction(action: EVacancyPageAction, obj: Record<string, unknown>) {
    this.pageActions[action] = { ...this.pageActions[action], ...obj };
  }

  isAuthenticated(isCandidate = true) {
    return isCandidate
      ? this.vacancy?.isCandidate && this.vacancy.isAuthenticated
      : this.vacancy?.isAuthenticated;
  }

  onAction(value: EVacancyPageAction) {
    switch (value) {
      case EVacancyPageAction.Apply:
        !this.isAuthenticated(false)
          ? (this.showApplyDialog = true)
          : this.applyAction();
        break;
      case EVacancyPageAction.Save:
        this.saveAction();
        break;
      case EVacancyPageAction.Remind:
        this.showRemindDialog = true;
        break;
      case EVacancyPageAction.Share:
        this.setAction(EVacancyPageAction.Share, { loading: true });
        this.shareAction();
        setTimeout(() => {
          this.setAction(EVacancyPageAction.Share, { loading: false });
        }, 2000);
        break;
    }
  }

  async applyAction() {
    if (!this.vacancy) return;

    try {
      this.setAction(EVacancyPageAction.Apply, { loading: true });
      await VacancyService.apply(this.vacancy?.id);
      this.setAction(EVacancyPageAction.Apply, {
        disabled: true,
      });
    } catch (e) {
      console.log("Apply error: ", e);
    } finally {
      this.setAction(EVacancyPageAction.Apply, { loading: false });
    }
  }

  async saveAction() {
    if (!this.vacancy) return;

    try {
      this.setAction(EVacancyPageAction.Save, { loading: true });
      await VacancyService.save(this.vacancy?.id);
      this.setAction(EVacancyPageAction.Save, {
        disabled: true,
      });
    } catch (e) {
      console.log("Save error: ", e);
    } finally {
      this.setAction(EVacancyPageAction.Save, { loading: false });
    }
  }

  shareAction() {
    // Can't use clipboard API, because not supported on macOS (tested on Mojave)
    const dummy = document.createElement("input");

    document.body.appendChild(dummy);
    dummy.value = window.location.href;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }
}
