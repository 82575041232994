

































































import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { VacancyCreatorModel } from "@/models/Vacancy";
import VacancyPageAction from "./models/VacancyPageAction";
import EVacancyPageAction from "./models/EVacancyPageAction";

@Component({
  name: "VacancyCreatorSection",
  components: {},
})
export default class extends Vue {
  @Prop({ required: true, default: [] })
  public readonly pageActions!: VacancyPageAction[];

  @Prop({ required: true, default: null })
  public readonly creator!: VacancyCreatorModel;

  private EVacancyPageAction = EVacancyPageAction;

  get avatar() {
    return this.creator?.avatar
      ? this.creator?.avatar?.resizedUrl
      : require("@/assets/no-avatar.svg");
  }

  @Emit()
  action(value: EVacancyPageAction) {
    return value;
  }
}
